<template>
  <div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item>
        <el-breadcrumb-item>退款单</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button type="success" style="height: 36px" @click="reload">
        <i style="font-size: 18px" class="el-icon-refresh-right"></i>
      </el-button>
    </div>

    <div style="padding: 20px 20px 0; white-space: nowrap">
      <el-row>
        <el-col :span="6">
          <span>创建日期：</span>
          <el-date-picker v-model="t1" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-col>
        <!-- <el-col :span="4">
          <el-select v-model="option1" clearable filterable placeholder="状态">
            <el-option v-for="item in option1es" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
          <el-select v-model="option1" clearable filterable placeholder="类型" style="margin: 0 5px">
            <el-option v-for="item in option1es" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </el-col> -->

        <el-col :span="6">
          <el-input v-model="query.search" placeholder="输入要查询的编号" style="width: 70%"></el-input>
          <el-button type="success" size="mini" style="margin-left: 5px" @click="handleQuery()">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
              <span style="margin-left: 3px">查询</span>
            </div>
          </el-button>
        </el-col>
      </el-row>
    </div>

    <div style="padding: 20px">
      <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border style="width: 100%"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"> </el-table-column>
        <!-- <el-table-column sortable label="创建日期">
          <template slot-scope="scope">{{ scope.row.date }}</template>
        </el-table-column> -->
        <el-table-column prop="create_time" sortable label="创建日期"> </el-table-column>
        <el-table-column prop="order_number" sortable label="付款单">
        </el-table-column>
        <el-table-column prop="member_name" label="付款人"> </el-table-column>
        <el-table-column prop="station" label="收款人"> </el-table-column>
        <el-table-column prop="sum" sortable label="退款金额">
        </el-table-column>
        <!-- <el-table-column prop="remark" label="备注"> </el-table-column>
        <el-table-column prop="type" label="类型"> </el-table-column> -->
        <el-table-column prop="payment" label="支付方式"> </el-table-column>
        <el-table-column prop="refund_time" sortable label="退款日期">
        </el-table-column>
        <el-table-column prop="status_code" label="状态"> </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <img style="width: 16px; height: 16px; cursor: pointer" @click="handleEdit(scope.$index, scope.row)"
              :src="require('@/assets/iconImg/logo.png')" />
            <span style="margin: 0 10px"></span>
            <img style="width: 16px; height: 16px; cursor: pointer" @click="handleDelete(scope.$index, scope.row)"
              :src="require('@/assets/iconImg/logo.png')" />
          </template>
        </el-table-column>
      </el-table>
      <!-- <div class="tableBottom">
        <span v-if="isResult" style="">显示第&nbsp;1&nbsp;至&nbsp;{{
          tableFinshNum
        }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span>
        <span v-if="!isResult" style="">显示&nbsp;{{ tableFinshNum }}&nbsp;项结果，共&nbsp;{{
          tableFinshNum
        }}&nbsp;项</span>
        <div style="display: flex; justify-content: flex-end; align-items: center">
          <el-button>首页</el-button>
          <el-button>上页</el-button>
          <el-pagination background layout=" pager,slot" :total="1"></el-pagination>
          <el-button>下页</el-button>
          <el-button>末页</el-button>
        </div>
      </div> -->
      <pagination :handleQuery="handleQuery" :currentPage="query.currentPage" :currentLength="query.currentLength"
        :total="total">
      </pagination>
    </div>
  </div>
</template>
<script>
import pagination from "@/components/pagination/index.vue";
export default {
  components: { pagination },
  data() {
    return {
      total: 0,
      t1: "",
      query: {
        commercial_id: localStorage.getItem("commercial_id") || sessionStorage.getItem("commercial_id"),
        firstTime: "",
        lastTime: "",
        search: "",
        currentPage: 1,
        currentLength: 10
      },
      creatTime: "",

      option1: "",
      option1es: "",
      QueryContent: "",

      tableData: [],
      multipleSelection: [],
      isResult: false,
      tableFinshNum: 0,
    };
  },
  mounted() {
    this.handleQuery();
    if (this.tableData.length == 0) {
      this.isResult = false;
    } else {
      this.isResult = true;
      this.tableFinshNum = this.tableData.length;
    }
  },
  watch: {
    t1(n) {
      this.query.firstTime = getDate(n[0]);
      this.query.lastTime = getDate(n[1]);
    }
  },
  computed: {},
  methods: {
    async handleQuery(page = 1) {
      this.query.currentPage = page;
      let res = (await this.$http.post("/Finance/Finance/refund", this.query)).data
      this.total = res.count
      this.tableData = res.data
    },
    reload() {
      location.reload();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    }
  },
};
</script>
<style scoped lang="less">
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

/deep/ .el-col {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  font-size: 14px;
  margin: 0 5px;
}

/deep/ .cell {
  padding: 0 !important;
  white-space: nowrap !important;
}
</style>
